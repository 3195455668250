.popup {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('./images/placeholder.jpg');
    background-size: cover;
    z-index: 99;
    display: flex;
    overflow: auto;
    justify-content: center;
}

@media(max-width: 760px) {
    .popup {
        padding: 0;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }
}

.back-button {
    border: none;
    background: transparent;
}

@media(max-width: 520px) {
    .back-button {
        position: absolute;
        top: 1.5rem;
        left: 1rem;
    }
}

.back-button:hover {
    cursor: pointer;
}

.right-section {
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1 1 auto;
    background-repeat: no-repeat;
    background-size: 90% auto;
    background-position: center top 2rem;
    max-height: clamp(30rem, 98vh, 51rem);    
}

.right-section .dish {
    text-align: center;
    position: relative;
    height: 100%;
}

.right-section .dish::after {
    position: absolute;
    left: calc(50% - .25rem);
    top: 0;
    display: block;
    content: "";
    height: 100%;
    border-left: .8rem solid #fff;
}

.right-section .dish .title {
    width: 35rem;
    max-width: 90%;
    margin-top: .5rem;
    z-index: 2;
    position: relative;
    margin-left: 1.5rem;
}

@media(max-width: 760px) {
    .right-section .dish {
        display: none;
    }
}

.right-section .swiper {
    width: 100%;
}

@media(min-width: 761px) {
    .back-button {
        order: 1;
        position: relative;
        align-self: flex-start;
    }
    .right-section {
        order: 2;
    }
}

.right-section .tooltip:hover {
    cursor: pointer;
}

/* .right-section .tooltip::before {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    width: 4rem;
    height: 4rem;
    background-size: cover;
} */

.right-section .tooltip .large-text {
    color: #9CCB55;
    font-size: 1.875rem;
    margin-right: 1rem;
}

.right-section .tooltip .small-text {
    font-size: 0.875rem;
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 0;
}

.money-tooltip::before {
    bottom: -1.9rem;
    left: -2.6rem;
    background-image: url(./images/money-icon.svg);
}

.protein-tooltip::before {
    bottom: -1rem;
    left: -3rem;
    background-image: url(./images/heart-icon.svg);
}

.environment-tooltip::before {
    bottom: -1rem;
    left: -3rem;
    background-image: url(./images/cloud-icon.svg);
}

@media(max-width: 520px) {
    .money-tooltip {
        align-self: center;
    }

    .protein-tooltip {
        align-self: flex-end;
    }

    .right-section {background-image: none !important}

}

.card-top h2 {
    color: #006F32;
    font-size: 2.1875rem;
    margin-bottom: 0.5rem;
}

.card-top .recipe-description {
    margin-top: 0;
    margin-bottom: 1.5rem;
}

.card-bottom h3 {
    color: #006F32;
    margin-bottom: 0.5rem;
    font-size: 1.125;
}

span .info-icon {
    position: static;
    vertical-align: middle;
    margin-left: .25rem;
    display: inline-block;
}

.info-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 1.25rem;
    height: auto;
}

.tooltip:hover .info-icon .path {
    fill: #006F32;
}

.counter-label {
    font-weight: 600;
    font-size: 1.125rem;
    flex-grow: 1;
}

.recipe-button {
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: #9CCB55;
    border-radius: 0.3rem;
    padding: 0.7rem;
    color: #fff;
    width: calc( 100% - 1.4rem );
    border: none;
    font-size: 1.375rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
}

.recipe-button:hover {
    background-color: #006F32;
}

.recipe-button img {
    margin-left: 0.5rem;
}

@media(min-width: 521px) {
    .recipe-button {
        margin-top: 3rem;
    }
}