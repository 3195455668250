.card {
    width: 220px;
    height: 280px;
    perspective: 1000px;
    position: relative;
    border-radius: var(--border-radius);
    padding: 0.6rem 0;
}

.card-face {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.5s;
    transform: rotateY(0deg) skew(3deg, -2deg); /* Start from the back face */
}

.card-face.back::after {
    position: absolute;
    display: block;
    content: "";
    background-image: url('./images/icon-turn-around.svg');
    background-size:contain;
    background-repeat: no-repeat;
    width: 1.2em;
    height: 1em;
    right: 1.2em;
    top: 1.2em;
}

.front {
    color: #333;
}

.back {
    color: #fff;
    transform: rotateY(180deg); /* Rotate the back on load so it's already on the back */
    transform: rotateY(-180deg); /* Start from the back face */
}

.recipe-title {
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    height: 4.7rem;
    margin-top: -1px; /* bugfix for not quite overlapping background colors */
}

.recipe-title h2 {
    margin-top: 0;
    font-family: 'Barlow';
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: none;
}

.front .recipe-title {
    background-color: #006F32;
    color: #fff;
    display: flex;
}

.front .recipe-title .arrow-right {
    width: 0.7rem;
}

 .front .recipe-title:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    width: 5.5rem;
    height: 5rem;
    background-size: contain;
}

.bean-lentils .front .recipe-title::after { background-image: url(./images/flip-card-lentils.png);}
.bean-chickpeas .front .recipe-title::after { background-image: url(./images/flip-card-chickpeas.png);}
.bean-kidneys .front .recipe-title::after { background-image: url(./images/flip-card-kidneys.png);}
.bean-white .front .recipe-title::after { background-image: url(./images/flip-card-white.png);}

.back .recipe-title {
    background-color: #fff;
    color: #333;
}

/* Flip the card on pageload */
.card.flip .front {
    transform: rotateY(180deg);
}

.card.flip .back {
    transform: rotateY(0deg);
}

/* Flip the card on hover */
.card.flip:hover .front {
    transform: rotateY(360deg) skew(3deg, -2deg);
}

.card.flip:hover .back {
    transform: rotateY(180deg);
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px !important;
    height: 280px;
}

.swiper-slide:hover {
    cursor: pointer;
}

.card-image-wrapper {
    padding: 0.7rem;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
}

.front .card-image-wrapper {
    background-color: #006F32;
}

.back .card-image-wrapper {
    background-color: #fff;
}

.card-image {
    display: block;
    width: 100%;
    height: auto;
    pointer-events: none /* this to disable microsoft edge visual search thingy */
}

.calculation-card {
    margin: 1.5rem;
    flex-basis: content;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    background-color: #fff;
    padding: 1rem 2rem;
    max-width: 34rem;
    min-width: 32rem;
}

.calculation-card .card-bottom {
    font-size: 1.3rem;
}

@media(min-width: 761px) {
    .calculation-card {
        border-radius: var(--border-radius);
        min-width: 20rem;
        width: calc(100vw - 6rem);
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

@media(max-width: 760px) {
    .calculation-card {
        min-width: 0;
    }
}

.counter {
    background-color: #F2F9E9;
    padding: 0.5rem 1.2rem;
    border-radius: 99rem;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.calculation-card .results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media(max-width: 760px) {
    .right-section .results {
        margin: 1rem;
    }
    .calculation-card {
        margin: 0;
        padding: .5rem 1.25rem;
    }
    .calculation-card .counter-label {
        font-size: 1rem;
    }
}

.button-calculate-container {
    text-align: right;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button-calculate {
    display: inline-block;
    color: #fff;
    background-color: var(--accent-color);
    border: none;
    height: 3rem;
    /* width: 1.5rem; */
    font-size: 1.5rem;
    padding: .5rem 1rem;
    border-radius: .6rem;
    font-family: 'derivat_no1regular';
    text-transform: uppercase;
    line-height: 0;
    text-align: center;
    cursor: pointer;
}

.icon-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding: .5rem 1rem;
    text-transform: uppercase;
    font-family: 'derivat_no1regular';
    font-size: 2.5rem;
    border-radius: var(--border-radius);
    position: relative;
    height: 6.5rem;
}

.icon-block.euro-savings {
    background-color: var(--highlight-color);
}

.icon-block .text {
    font-size: .6em;
}
.icon-block .icon {
    min-width: 5rem;
    max-width: 5rem;
}

@media(max-width: 760px) {
    .icon-block {
        gap: 0;
    }
    .icon-block .icon {
        min-width: 3.5rem;
        max-width: 3.5rem;
        margin-right: 1.5rem;
    }
    .icon-block .text {
        margin-right: 1rem;
        font-size: clamp(1rem, 5vw, 10vw);
    }
}

.icon-block .euro-savings {
    width: min-content;
    text-wrap:nowrap;
    display: block;
    margin-left: auto;
    font-size: clamp(1rem, 2rem, 2rem);
}

.icon-block-co2 {
    background-color: #fff;
    color: var(--accent-color);

}

.calculation-card .buttons-wrapper {
    gap: .25rem;
    display: flex;
    align-items: center;
}

.calculation-card .buttons-wrapper .amount {
    width: 1.2em;
    min-width: 1.2em;
    font-size: .8em;
    text-align: center;
}

.calculation-card .buttons-wrapper button {
    color: #fff;
    /* background: url(./images/round-button-bg.svg); */
    mask: url(./media/round-button-bg.svg) no-repeat center / cover;
    background-color: #9CCB55;
    background-size: cover;
    border: none;
    height: 1.5rem;
    min-width: 1.5rem;
    font-size: 1.5rem;
    padding: 0;
    line-height: 0;
    text-align: center;
}

.buttons-wrapper button:hover {
    background-color: #006F32;
    cursor: pointer;
}

.buttons-wrapper button.disabled {
    background-color: #dedede;
}

.buttons-wrapper button.disabled:hover {
    cursor:auto;
}

.results .highlight, .results select {
    background-color: #fff;
    border: none;
    border: 1px solid var(--accent-color-light);
    border-radius: 99rem;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 1rem;
    font-weight: 500;
    margin-right: -.4rem;
    max-width: 10rem;
    flex-basis: 10rem;
    padding: .5rem .5rem .5rem 1.5rem;
    position: relative;
    text-indent: -1px;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10rem;
    padding-right: 2rem;
}

.results .select-wrapper {
    position: relative;
}

.results .select-wrapper::after {
    display: block;
    content: "";
    width: .5em;
    height: .5em;
    position: absolute;
    right: .6em;
    bottom: .5em;
    pointer-events: none;
    background-image: url('./images/select-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
}

.results .highlight::placeholder {
    color: #333;
  }

.results .counter-label {
    position: relative;
    display: block;
    text-wrap: nowrap;
}

.currency .results .counter-label::after {
    font-weight: 500;
    content: "€";
    position: absolute;
    width: 1rem;
    right: 0;
    margin-right: 0;
    margin-top: 0;
}

/* grow inputfield on input if needed: https://css-tricks.com/auto-growing-inputs-textareas/ */
.input-sizer {
    display: grid;
    grid-template-columns: 1fr auto;
}

.input-sizer::after,
.input-sizer input,
.input-sizer textarea {
    grid-area: 1/2;
}