.popup-alternative {
    position: fixed;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-image: none;
    background-color: #006F32de;
    color: #fff;
}

.popup-alternative .popup-content {
    width: calc(100vw - 5rem);
    margin: 0 auto;
    margin-bottom: auto;
    margin-top: 3rem;
    max-width: clamp(10rem, 40rem , calc(100vw - 4rem) );

}

.popup-alternative .popup-content h1 {
    color: #fff;
    margin-bottom: .6em;
}

.popup-alternative .popup-content p, .popup-alternative .popup-content ul {
    margin-bottom: 1.5em;
}

.popup-alternative .popup-content li {
    list-style-type: disc;
    margin-left: 1.5rem;
}

.popup-alternative .popup-content a {
    text-decoration: underline;

}

.popup-alternative .popup-content a:hover {
    cursor: pointer;
    text-decoration: none;
}


.close-button {
    position: sticky;
    top: 1.5rem;
    margin-right: -1.5rem;
    width: 2.5rem;
    height: auto;
    margin-left: auto;
    cursor: pointer;
    display: block;
    margin-top: -3rem;
}

.close-button:hover .path {
    fill:#006F32;
}