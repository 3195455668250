@import "the-new-css-reset/css/reset.css";

/* barlow-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/barlow-v12-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/barlow-v12-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Barlow";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/barlow-v12-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

@font-face {
  font-family: "Love-Ya-Like-A-Sister-Solid";
  src: url("./fonts/webfonts_Love-Ya-Like-A-Sister-Solid/Love-Ya-Like-A-Sister-Solid.ttf.woff")
      format("woff"),
    url("./fonts/webfonts_Love-Ya-Like-A-Sister-Solid/Love-Ya-Like-A-Sister-Solid.ttf.svg")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "derivat_no1regular";
  src: url("./fonts/webfontkit-DerivatNo1-20240611-084232/derivat-no1-webfont.woff2")
      format("woff2"),
    url("./fonts/webfontkit-DerivatNo1-20240611-084232/derivat-no1-webfont.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Global styling */
html {
  background-color: #edf2f2;
  font-family: Barlow;
}

body {
  overflow: hidden;
}

:root {
  --accent-color: #779643;
  --accent-color-light: #77964360;
  --highlight-color: #fad347;
  --border-radius: 0.8rem;
  --swiper-navigation-size: 80px !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

h1 {
  font-family: "Love-Ya-Like-A-Sister-Solid";
  color: var(--accent-color);
  font-size: 2.5rem;
  margin: 0.3em 0;
}

h2 {
  font-family: "derivat_no1regular";
  font-size: 1.6rem;
  text-transform: uppercase;
}

h2 .small-text {
  margin-top: 0;
}

h2 .large-text {
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.05rem;
  line-height: 1.3;
}

@media (max-width: 760px) {
  p {
    font-size: 1.1rem;
  }
}

sub {
  font-size: 0.7em;
  vertical-align: sub;
}

.smaller {
  font-size: 0.7em;
}

/* Tools */
@media (max-width: 720px) {
  .mobile-only {
    display: block;
  }

  .desktop-only {
    display: none;
  }
}

@media (min-width: 721px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: block;
  }
}

@media (min-width: 721px) {
  .header-wrapper {
    display: flex;
  }
}

.header-texts {
  margin: 0 1rem;
}

.header {
  font-size: 2.375rem;
  text-transform: uppercase;
  color: #006f32;
}

@media (min-width: 721px) {
  .header {
    font-size: 3rem;
  }
}

.secondary-header {
  font-size: 26px;
  color: #9ccb55;
  text-transform: uppercase;
}

@media (min-width: 721px) {
  .secondary-header {
    font-size: 34px;
  }
}

.hero-leaf.mobile-only {
  margin-bottom: 1.5rem;
}

.secondary-header-wrapper {
  position: relative;
  display: flex;
}

@media (max-width: 720px) {
  .mobile-break {
    display: block;
  }
}

@media (min-width: 721px) {
  .desktop-break {
    display: block;
  }
}

.arrow-bend {
  margin-left: -0.3rem;
  top: 0;
  width: 4rem;
}

@media (min-width: 721px) {
  .arrow-bend {
  }
}

.swiper-horizontal {
  padding: 0 1rem;
}

.footer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 721px) {
  .footer {
    position: absolute;
    justify-content: flex-end;
    right: 0;
    bottom: 0;
  }
}

.hak-button {
  text-align: center;
}

.hak-button:hover {
  cursor: pointer;
}

.right-section {
  max-width: 62rem;
  position: relative;
  justify-content: space-between;
}

.right-section h2 {
  text-align: center;
}

.right-section .hak-button {
  max-width: 12rem;
  margin: auto 1.5rem 0.5rem auto;
}

.right-section .results {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: var(--border-radius);
  padding: 1rem;
  margin: 1rem;
  background-color: #fff;
  max-width: 34rem;
  margin: auto;
}

/* Swiper styling */
.swiper-button-prev::after,
.swiper-button-next::after {
  display: none;
}

.button-label {
  text-indent: -9999px;
}

.tooltip {
  cursor: pointer;
  padding-right: 1.5rem;
}

.recipe-cards {
  padding: 1rem;
  margin-top: 4rem;
}

.disclaimer {
  margin-left: 0.5rem;
  text-align: right;
  margin-bottom: 0.5rem;
  text-decoration: underline;
  color: var(--accent-color);
}
